<template>
  <v-app class="transparent">
    <v-container id="regular-tables" fluid tag="section">
      <base-v-component heading="Sheet List"/>

      <base-material-card
        icon="mdi-google-spreadsheet"
        title="Sheets List"
        class="px-5 py-3">
        <v-simple-table>

          <filter-list
            :filters="filter"
            :on-change="getSheets">
          </filter-list>

          <tbody>
          <tr v-for="sheet in sheets">
            <td>{{ sheet.id }}</td>
            <td>{{ sheet.name }}</td>
            <td>{{ sheet.status | sheetStatus }}</td>
            <td>{{ sheet.created_at }}</td>
            <td>{{ sheet.updated_at }}</td>
            <td class="text-center">
              <div class="d-inline-flex">
                <v-btn
                  v-if="$auth.isAdmin()"
                  x-small fab
                  color="green lighten-2"
                  class="mr-2" @click="approveSheet(sheet)"
                  :disabled="sheet.status !== 'pending_approval'"
                  :depressed="sheet.status === 'approved' || sheet.status === 'completed'">
                  <v-icon v-if="sheet.status === 'pending_approval'">mdi-check-bold</v-icon>
                  <v-icon v-else>mdi-check-all</v-icon>
                </v-btn>
                <v-btn :href="sheet.document" target="_blank" x-small fab color="blue lighten-2" class="mr-2 ml-2">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn x-small fab color="red darken-2" class="ml-2" @click="openDeleteDialog(sheet)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn :disabled="!sheet.has_errors" x-small fab color="red darken-3" class="ml-2" :to="'sheets/' + sheet.id + '/failed-rows'">
                  <v-icon>mdi-alert-circle</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </base-material-card>
      <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
          v-model="query.page"
          :length="pagination.length"
          :total-visible="7"
          @input="getSheets"
          @next="getSheets"
          @previous="getSheets"/>
      </div>
      <div class="py-3"/>
      <v-btn x-large fab dark fixed right bottom color="blue"  to="/sheets/create">
        <v-icon large>mdi-plus</v-icon>
      </v-btn>

      <delete-dialog ref="deleteDialog" :on-delete="deleteSheet"/>
    </v-container>
  </v-app>
</template>

<script>
  import DeleteDialog from "../../components/base/DeleteDialog";
  import FilterList from "../../components/base/FilterList";

  export default {
    name: "SheetList",
    components: {FilterList, DeleteDialog},
    created() {
      this.getUrlParams();
      this.getSheets();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getSheets();
      }
    },
    data() {
      return {
        sheets: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "name",
            value: "",
            type: "text_field"
          },
          {
            name: "status",
            value: "",
            type: "select",
            items: [
              {text: "Pending Approval", value: 'pending_approval'},
              {text: "Approved", value: 'approved'},
              {text: "Completed", value: 'completed'},
            ]
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
          {
            name: "updated_at",
            value: "",
            type: "text_field"
          },
        ]
      }
    },
    methods: {
      getSheets() {
        this.$authApi.get(`/user-sheets`, {
          params: this.query
        }).then(res => {
          this.sheets = res.data.data.data;
          this.pagination.length = res.data.data.last_page;
          this.pagination.total = res.data.data.total;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "name",
              value: "",
              type: "text_field"
            },
            {
              name: "status",
              value: "",
              type: "select",
              items: [
                {text: "Pending Approval", value: 'pending_approval'},
                {text: "Approved", value: 'approved'},
                {text: "Completed", value: 'completed'},
              ]
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
            {
              name: "updated_at",
              value: "",
              type: "text_field"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      },
      openDeleteDialog(sheet) {
        this.$refs.deleteDialog.openDialog(sheet);
      },
      deleteSheet(sheet) {
        this.$authApi.delete(`/user-sheets/${sheet.id}`).then(res => {
          this.getSheets();
        })
      },
      approveSheet(sheet) {
        this.$authApi.put(`/user-sheets/${sheet.id}/approve`).then(res => {
          this.getSheets();
        })
      }

    }
  }
</script>
